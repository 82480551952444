@import 'variables';

$prefix: $antPrefix + -list;

.#{$prefix} {
  color: $textColor;
  &-item-meta {
    &-title,
    &-description {
      color: $textColor;
    }
  }
  &-item {
    border-color: $borderColor;
  }
}

.#{$prefix}.survey-list {
  .#{$prefix}-item {
    border: 1px solid $primaryColor;
    background: $clientColor;
    padding: 12px 16px;
    margin-bottom: 12px;
    transition: box-shadow linear 0.3s;

    .#{$prefix}-item-action {
      li {
        padding: 0;

        .anticon:not(.anticon-container) {
          color: $primaryColor;
        }
      }
    }

    &:hover {
      box-shadow: 2px 2px $primaryColor;
    }
  }
}