@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {

      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }

      &-item-label {
        background: $contrastBackground;
      }
    }
  }

  &-title,
  &-item-label,
  &-item-content {
    color: $textColor;
  }
}

.#{$prefix}-item-container {
  flex-direction: column;

  .#{$prefix}-item-label {
    text-transform: uppercase;
    color: $textColorSecondary;
    font-size: 0.9em;
  }

  .#{$prefix}-item-content article {
    font-weight: 600;

    a {
      color: $textColor;
      text-decoration: underline;

      &:hover {
        color: $secondaryColor;
      }
    }
  }
}