@import 'variables';

$prefix: $antPrefix + -timeline;

.#{$prefix} {
    .#{$prefix}-item {
        .#{$prefix}-item-head-blue {
            color: $primaryColor;
            border-color: $primaryColor;
            background-color: $primaryColor;
            width: 8px;
            height: 8px;
        }

        .#{$prefix}-item-tail {
            left: 4px;
            border-left: 1px solid $borderColor;
        }
    }

    .#{$prefix}-item-last {
        .#{$prefix}-item-tail {
            display: none;
        }
    }
}

.#{$prefix}-item .#{$prefix}-item-content>.#{$antPrefix}-row>.#{$antPrefix}-col:first-child,
.#{$prefix}-item-last>.#{$antPrefix}-col:first-child>.#{$prefix}-item .#{$prefix}-item-content {
    font-size: 13px;
    font-weight: 600;
}

.#{$prefix}-item .#{$prefix}-item-content>.#{$antPrefix}-row>.#{$antPrefix}-col:last-child,
.#{$prefix}-item-last>.#{$antPrefix}-col:last-child {
    font-size: 13px;
    text-align: right;
    color: $textColorSecondary;
}