@import 'variables';

$prefix: $antPrefix + -modal;
$drawerPrefix: $antPrefix + -drawer;
$modalTypes: '&-confirm, &-info, &-success, &-error, &-warning';

.#{$prefix},
.#{$drawerPrefix} {
  color: $textColor;

  &-wrap.no-padding,
  &.no-padding {
    .#{$prefix} {
      &-body {
        padding: 0 !important;
      }

      &-content {
        overflow: hidden;
      }
    }
  }

  &-content {
    background-color: $clientColor;
  }

  &-close {
    color: $textColorSecondary;

    &:hover {
      color: $textColor;
    }
  }

  &-title {
    color: $textColor;
    font-family: $titleFont;
    letter-spacing: 0.02em;
    font-weight: 400;
    font-size: 20px;
  }

  &-header,
  &-footer {
    color: $textColor;
    background: transparent;
    border-color: $borderColor;
  }

  &-header {
    border: none;
  }

  #{$modalTypes} {
    &-body .#{$prefix} {
      #{$modalTypes} {

        &-title,
        &-content {
          color: $textColor;
        }
      }
    }
  }

  &-confirm-info .#{$prefix}-confirm-body>.anticon {
    color: $primaryColor;
  }
}

.session-modal {
  .#{$prefix}-content {
    h3 {
      font-family: $titleFont;
      letter-spacing: 0.02em;
      font-weight: 400;
      font-size: 18px;
    }

    .session-class-number {
      display: block;
      color: $primaryColor;
      border: 1px solid $primaryColor;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      text-align: center;
      padding-right: 1px;
      margin-top: 3px;
    }
  }
}

.#{$prefix} {
  .rdw-option-wrapper {
    border: none;
    background: none;
  }

  .#{$antPrefix}-btn:not(.#{$antPrefix}-btn-dashed):not(.#{$antPrefix}-btn-primary):not(.#{$antPrefix}-btn-add) {
    border: none;
    background: none;
  }

  .rdw-editor-main {
    background-color: white;

    .public-DraftStyleDefault-block {
      margin: 0.5rem;
      background: white;
    }
  }
}