@import 'variables';

.badge-wait,
.badge-acquired {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;

    .badge-icon {
        display: flex;
        width: 26px;
        height: 26px;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
    }
}

.badge-acquired {
    background: $primaryColor;

    .badge-icon {
        color: $primaryContrast;
        border: 1px solid $primaryContrast;
    }
}

.badge-wait {
    background: $lightGray;

    .badge-icon {
        color: $borderColor;
        border: 1px solid $borderColor;
    }
}