@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label>label {
    color: $textColor;
  }

  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }

  &-item {
    margin-bottom: 10px;
  }
}

.#{$prefix}-vertical {
  >.#{$prefix}-item {
    margin-bottom: 20px;
  }

  .#{$prefix}-item-label {
    padding-bottom: 4px;
  }
}