@import "variables";

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: $borderColor;
  height: 50px;
}
