@import 'variables';

$prefix: $antPrefix + -divider;

.#{$prefix} {
  &-horizontal {
    &.#{$prefix} {
      border-color: $borderColor;
      &-with-text-center,
      &-with-text-left,
      &-with-text-right {
        color: $textColor;
        border-color: $borderColor;
        &::before,
        &::after {
          border-color: $textColor;
        }
      }
    }
  }
}

.#{$prefix}-horizontal.#{$prefix}-with-text-left {

  margin-top: 0;

  &::before {
    display: none;
  }

  .#{$prefix}-inner-text {
    padding-left: 0;
    font-family: $titleFont;
    font-size: 18px;
    letter-spacing: 0.02em;
    font-weight: 400;
  }
}