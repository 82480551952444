@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  color: $textColor;
  background: $secondaryColor;
  border-radius: 0%;

  &-back-button,
  &>.#{$antPrefix}-breadcrumb a,
  &>.#{$antPrefix}-breadcrumb>span:last-child a {
    color: $textColor;

    &:hover {
      color: $textColorSecondary;
    }
  }

  &>.#{$antPrefix}-breadcrumb>span:last-child a {
    cursor: auto;
  }

  .#{$antPrefix}-breadcrumb-separator {
    color: $textColorSecondary;
  }

  .#{$prefix}-heading-title {
    color: $clientColor;
    background: $primaryColor;
    font-family: $titleFont;
    font-weight: 400;
    letter-spacing: 0.02em;
  }

  .#{$prefix}-heading-extra {
    .#{$antPrefix}-space-item {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}