@import 'variables';

$prefix: $antPrefix + -tag;

.#{$prefix} {
  background: transparent;
  border-color: $borderColorA06;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  a,
  a:hover {
    color: $textColorSecondary;
  }
}

.config-tag {
  padding: 2px 8px;
  letter-spacing: 0.05em;
  border: 0;
  min-width: 72px;
  text-align: center;
}

.masterclass-topic,
.course-category {
  border: 0;
  padding: 4px 10px;
}

.masterclass-topic {
  background-color: $clientColor;
}

.course-category {
  background-color: #e2e2e2;
}

.status-tag {
  color: $textColorSecondary;
  padding: 2px 8px;
  border: 0;
}