@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;

  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }

  &-head {
    border-bottom-color: $borderColor;
  }

  &-bordered {
    border-color: $borderColor;
  }
}

.#{$prefix}.message-item.non-read {
  box-shadow: 9px 11px 0px 0px #ffd33b;
  -webkit-box-shadow: 9px 11px 0px 0px #ffd33b;
}
.#{$prefix}.question-card {
  margin-bottom: 16px;

  .#{$prefix}-head {
    min-height: unset;
    padding: 8px 12px 8px 20px;

    .#{$prefix}-head-title {
      padding: 0 8px 0 0;

      .#{$antPrefix}-form-item {
        margin: 0;
      }
    }

    .#{$prefix}-extra {
      padding: 0;
    }
  }

  .#{$prefix}-body {
    >.#{$antPrefix}-row {
      margin-bottom: 8px;
    }

    .#{$antPrefix}-row .#{$antPrefix}-form-item {
      margin: 0;
    }
  }

  .anticon-close {
    color: $errorColor;
  }
}

.#{$prefix}.formation-card {
  margin-bottom: 24px;
  background: $clientColor;
  border: 0;
  box-shadow: 4px 4px 8px rgba(16, 16, 16, 0.05);
  height: calc(100% - 24px);

  .#{$prefix}-head {
    border: 0;
    padding: 24px 24px 0 24px;

    .#{$prefix}-head-title {
      padding: 0;
      flex: unset;
      background: $primaryColor;
      color: $clientColor;
      font-family: $titleFont;
      letter-spacing: 0.02em;
      font-weight: 400;
    }
  }
}

.#{$prefix}.challenge-card {
  background: $clientColor;
  border-color: $primaryColor;
  box-shadow: 4px 4px $primaryColor;
  min-width: fit-content;
  margin-bottom: 24px;

  .#{$prefix}-head {
    padding: 12px;
    border: 0;

    .#{$prefix}-head-title {
      padding: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.#{$prefix}.chapter-card {
  margin-bottom: 8px;
  background: $clientColor;
  border-color: $textColorSecondary;
  box-shadow: 4px 4px 0px $lightGray;

  .chapter-icon-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primaryColor;
    margin-right: 8px;
  }
}

.#{$prefix}.evaluation-card {
  margin-bottom: 16px;
}

.#{$prefix}.skill-list-card {
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 2px;
}

.#{$prefix}.skill-card {
  border: 1px solid rgba(16, 16, 16, 0.4);
  border-radius: 2px;
  margin-bottom: 8px;
}

.#{$prefix}.formation-skill-card {
  .#{$antPrefix}-descriptions {
    .#{$antPrefix}-descriptions-item-label {
      display: none;
    }

    .#{$antPrefix}-descriptions-item-content {
      .#{$antPrefix}-typography {
        width: 100%;

        .course-goals {
          .#{$antPrefix}-divider {
            margin: 12px 0;
          }
        }
      }
    }
  }
}

.#{$prefix}.quiz-card {
  background: $clientColor;
  border-color: $textColorSecondary;
  margin-bottom: 10px;

  .#{$prefix}-body {
    padding: 12px 16px;
  }
}

.evaluation-modal {
  .#{$antPrefix}-modal-body {
    >div>.#{$prefix}.chapter-card:not(:last-child) {
      margin-bottom: 20px;
    }

    >div>.#{$prefix}.chapter-card {

      .#{$prefix}-body {
        >div.#{$antPrefix}-row {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.skill-dragdrop-wrapper {
  >.#{$antPrefix}-col {

    >.#{$antPrefix}-typography,
    >span {
      color: $textColorSecondary;
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 8px;
      display: block;
    }

    .#{$prefix}.skill-list-card>.#{$prefix}-body {
      padding: 12px;
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;

      .#{$prefix}.skill-card {
        margin-bottom: 12px;
        transition: box-shadow 0.3s ease-out;

        .#{$prefix}-body {
          padding: 12px 16px;
        }

        &:hover {
          box-shadow: 4px 4px 0px #101010;
        }
      }

      .#{$prefix}.skill-card:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.virtual_classes-card {
  height: calc(100% - 24px);

  .#{$prefix}-body {
    >div {
      margin-bottom: 16px;

      h4 {
        margin-bottom: 0;
      }

      .anticon {
        font-size: 24px;
      }
    }

    >div:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.#{$prefix}.home-card {
  margin-bottom: 24px;
  background: $clientColor;
  border: 0;
  box-shadow: 4px 4px 8px rgba(16, 16, 16, 0.05);
  height: calc(100% - 24px);

  .#{$prefix}-head {
    border: 0;
    padding: 24px 24px 0 24px;

    .#{$prefix}-head-title {
      padding: 0;

      p {
        background: $primaryColor;
        color: $clientColor;
        font-family: $titleFont;
        letter-spacing: 0.02em;
        font-weight: 400;
        margin-bottom: 0;
        width: fit-content;
      }
    }
  }

  .#{$prefix}-body {
    .home-indicator {
      .#{$antPrefix}-col {
        padding: 0 8px;

        .#{$antPrefix}-row:first-child {
          font-family: $titleFont;
          font-size: 24px;
        }

        .#{$antPrefix}-row:last-child {
          color: $textColorSecondary;
          white-space: normal;
        }
      }
    }

    .dashboard-session-card {
      background: $clientColor;
      border-color: $borderColor;
      margin-bottom: 12px;

      .#{$prefix}-body {
        padding: 12px 16px;

        .#{$antPrefix}-btn-link {
          padding-right: 0;
          color: $secondaryColor  !important;
        }
      }
    }
  }
}
