@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label > label {
    color: $textColor;
  }
  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }
  &-item {
    margin-bottom: 10px;
  }
}

.login-form {
  .#{$prefix}-item {
    margin-bottom: 16px;
    .#{$antPrefix}-checkbox-wrapper {
      color: $textColorA015;
      &:hover {
        color: $textColorInvert;
      }
    }  
  }
  
  .#{$antPrefix}-input-affix-wrapper:not(.#{$antPrefix}-input-affix-wrapper-disabled) {
    background-color: rgba(255, 255, 255, 0.4);
    border: 0;
    .#{$antPrefix}-input {
      background-color: transparent !important;
    }
  }
  
  .#{$antPrefix}-btn-link {
    span {
      color: $textColorA015;
    }  
    &:hover {
      span {
        color: $textColorInvert;
      }
    }
  }
}

.skill-form {
  padding: 0 24px;
  > .#{$antPrefix}-row {
    margin-bottom: 12px;
    > .#{$prefix}-item {
      width: calc(100% - 75px);
      margin: 0;
    }

    > .#{$antPrefix}-btn-primary {
      margin: 0 8px;
    }

    > .anticon {
      font-size: 16px;
      color: $borderColor;
      transition: color 0.3s;
      &:hover {
        color: $errorColor;
      }
    }
  }
}