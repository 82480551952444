@import 'variables';

$prefix: $antPrefix + -pagination;
$disabledClasses: '.#{$prefix}-disabled a, .#{$prefix}-disabled:hover a, .#{$prefix}-disabled:focus a, .#{$prefix}-disabled .#{$prefix}-item-link, .#{$prefix}-disabled:hover .#{$prefix}-item-link, .#{$prefix}-disabled:focus .#{$prefix}-item-link';

.#{$prefix} {
  color: $textColor;
  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next,
  &-item {
    border: none;
    .#{$prefix}-item-link {
      background-color: transparent;
      border: none;
    }
    a {color: $textColorSecondary;}
    &:hover {
      a {
        color: $primaryHover;
      }
    }
    &-active {
      border-color: $primaryColor;
      a {
        color: $primaryColor;
        font-size: 16px;
      }
      &:hover {
        a {
          color: $primaryColor;
        }
      }
    }
    &:not(.#{$prefix}-disabled) {
      background-color: transparent;
      border-color: $borderColor;
      &:hover {
        border-color: $primaryColor;
        a {
          border-color: $primaryColor;
        }
      }
      .#{$prefix}-item-link,
      a {
        border-color: $borderColor;
      }
    }
    &-container,
    .#{$prefix}-item-container {
      .#{$prefix}-item-ellipsis {
        color: $disabledColor;
        a {
          color: $disabledColor;
        }
      }
      .#{$prefix}-item-link-icon {
        color: $primaryColor;
      }
    }
  }
}

#{$disabledClasses} {
  color: $disabledColor;
  border-color: $borderColor;
}

.#{$prefix}-options-size-changer.#{$antPrefix}-select, .#{$prefix}-options-size-changer.#{$antPrefix}-select .#{$antPrefix}-select-selector {
  border: none;
}