@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;

  >.#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }

  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab .#{$prefix}-tab-btn {

    &-active,
    &:focus,
    &:hover {
      color: $primaryColor;
    }

    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.user-tabs.#{$prefix} {
  >.#{$prefix}-nav {
    margin: 0;

    .#{$prefix}-nav-wrap {
      .#{$prefix}-nav-list {
        .#{$prefix}-tab {
          padding: 12px 16px;
          text-transform: uppercase;
          transition: all 0.3s;

          .#{$prefix}-tab-btn {
            color: $textColorSecondary;
            font-weight: 400;
            transition: all 0.3s;

            &:hover {
              color: $primaryColor;
            }
          }
        }

        .#{$prefix}-tab-active {
          background: $clientColor;
          border-radius: 4px 4px 0 0;

          .#{$prefix}-tab-btn {
            color: $primaryColor;
            font-weight: 600;
          }
        }

        .#{$prefix}-tab+.#{$prefix}-tab {
          margin: 0;
        }

        .#{$prefix}-ink-bar,
        .#{$prefix}-ink-bar-animated {
          display: none;
        }
      }
    }
  }

  >.#{$prefix}-content-holder>.#{$prefix}-content {
    background: $clientColor;
    border-radius: 0 4px 4px 4px;
    padding: 16px 16px 0;

    .#{$antPrefix}-table .#{$antPrefix}-table-tbody>tr:not(.#{$antPrefix}-table-expanded-row)>td,
    .#{$antPrefix}-table-pagination .#{$antPrefix}-select.#{$antPrefix}-select-single:not(.#{$antPrefix}-select-customize-input):not(.#{$antPrefix}-select-disabled) .#{$antPrefix}-select-selector {
      background: $clientColor;
    }
  }
}

.formation-tabs.#{$prefix} {

  .#{$prefix}-nav {
    .#{$prefix}-tab-active .#{$prefix}-tab-btn {
      color: $primaryColor;
    }

    .#{$prefix}-tab-btn {
      font-size: 16px;
    }
  }

  .#{$prefix}-content {
    .formation-card {
      height: calc(100% - 24px);
    }
  }
}