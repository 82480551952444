@import 'variables';

.inbox-counter {
    padding: 4px 8px;
    display: flex;
    gap: 4px;
    border: 1px solid $primaryColor;
    border-radius: 2px;
    margin-bottom: 0;
}

.inbox-wrapper {
    height: 100%;
    margin-top: 24px;
    min-height: calc(100vh - 278px);

    .sessions-column {
        .inbox-session-btn {
            display: flex;
            height: fit-content;
            padding: 4px 15px 4px 0;

            >.#{$antPrefix}-row {
                max-width: 100%;
            }

            h4,
            h5 {
                text-align: left;
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            h4 {
                margin-bottom: 4px;
                font-weight: 600;
            }
        }
    }

    .trainees-column {
        .inbox-trainee-btn {
            height: fit-content;
            border-radius: 0;

            h4 {
                margin-bottom: 4px;
            }
        }

        .inbox-trainee-btn:not(:last-child) {
            border-bottom: 1px solid $borderColor;
        }
    }

    .message-column {
        .message-item {
            border: 1px solid $textColorSecondary;
            box-shadow: 6px 6px 0px rgba(16, 16, 16, 0.15);
            margin-bottom: 24px;
            position: relative;
            background: $clientColor;

            .#{$antPrefix}-card-body {
                height: fit-content;
                padding: 14px 8px 14px 14px;

                .rdw-editor-main {
                    height: fit-content;

                    .DraftEditor-editorContainer {
                        background-color: transparent;
                        border: none;
                    }
                }
            }

            h3 {
                margin-bottom: 0;
            }

            .#{$antPrefix}-avatar {
                min-width: 32px;
            }
        }

        .message-item:not(.message-editor-card) {
            &::after {
                content: '';
                position: absolute;
                height: 24px;
                width: 1px;
                background-color: $textColorSecondary;
                left: 40px;
                bottom: -25px;
            }
        }

        .message-editor-card,
        .answer-inbox-container {
            a {
                color: $primaryColor;
            }

            .#{$antPrefix}-card-body {
                padding: 14px 20px 14px 14px;
            }

            .rdw-editor-main {
                background-color: $clientColor;

                .public-DraftStyleDefault-ltr {
                    margin: 4px 8px;
                    word-break: break-all;
                }
            }
        }

        .answer-inbox-container {
            background-color: $clientColor;
            border: 1px solid $textColorSecondary;
            box-shadow: 6px 6px 0px rgba(16, 16, 16, 0.15);
        }
    }
}

.inbox-wrapper.discussion-wrapper {
    flex-wrap: nowrap;

    >.sessions-column>.inbox-session-btn {
        padding: 4px 15px 4px 24px;

        .#{$antPrefix}-badge {
            max-width: 100%;

            h5 {
                white-space: normal;
            }

            >.#{$antPrefix}-badge-count {
                right: unset !important;
                left: -32px;
            }
        }
    }
}

.message-modal,
.answer-inbox-container {
    max-width: 100%;

    a {
        color: $primaryColor;
    }

    .rdw-editor-toolbar {
        background: transparent;
    }
}

.chapter-commentaries-section {
    max-width: 100%;

    .discussion-item {
        min-width: 100%;
        max-width: 100%;
        display: flex;
    }

    >.discussion-item {
        .discussion-card {
            border-color: $borderColor;
            margin-top: 12px;
            width: 100%;

            .expert-tag {
                margin: 0;
                background: rgba(255, 215, 75, 0.4);
                border: 0;
                color: $textColorSecondary;
            }

            .discussion-expert {
                background: rgba(255, 215, 75, 0.4);
                border-radius: 50px 0 0 50px;
            }

            .discussion-user {
                padding-right: 10px;
                font-weight: 600;
            }

            .comment-toggle {
                align-items: center;
                gap: 8px;
                color: $textColorSecondary;
                font-size: 12px;

                .#{$antPrefix}-btn-circle {
                    min-height: 28px;
                    max-height: 28px;
                    min-width: 28px;
                    max-width: 28px;
                    border-color: $textColorSecondary;

                    &:hover,
                    &:focus {
                        border-color: $primaryContrast;
                        color: $primaryContrast;
                    }
                }
            }
        }

        >.discussion-card {
            margin-top: 16px;
        }
    }

    >.discussion-item:last-child {
        margin-bottom: 16px;
    }

    .discussion-edit-form {
        margin: 16px 0 24px;
        width: 100%;

        >.#{$antPrefix}-form-item {
            margin-bottom: 10px;
        }

        .rdw-editor-toolbar {
            padding: 0;

            .rdw-option-wrapper {
                border: 0;
            }

            a:hover,
            a:focus {
                color: $textColor  !important;
            }
        }
    }
}

@media (min-width: 768px) {
    .sessions-column {
        border-right: 1px solid $textColorSecondary;
        min-height: 100%;
    }
}

@media (max-width: 767px) {
    .sessions-column {
        border-bottom: 1px solid $textColorSecondary;
    }

    .trainees-column {
        padding: 10px 0;

        .inbox-trainee-btn {
            padding: 4px 15px 4px 0;
        }
    }
}