@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
  &-sider,
  &-sider-children,
  &-sider-trigger {
    background: $primaryColor;
    a {
      color: $clientColor;
      transition: opacity 0.3s linear;
      &:hover {
        opacity: 0.5;
      }
    } 
  }

  &-content {
    border-radius: 0%;
  }
}

.logo {
  font-weight: 600;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    opacity: 1 !important;
  }
}