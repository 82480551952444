@import 'variables';

$prefix: $antPrefix + -typography;

.#{$prefix},
h1.#{$prefix},
h2.#{$prefix},
h3.#{$prefix},
h4.#{$prefix},
h5.#{$prefix},
h6.#{$prefix} {
    color: $textColor;
}

h3.#{$prefix} {
    font-size: 18px;
    font-family: $titleFont;
    letter-spacing: 0.02em;
    font-weight: 400;
    margin-bottom: 16px;
}