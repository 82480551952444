@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor;
  line-height: 35px;
  border-color: $primaryColor;

  &-borderless {
    background-color: $clientColor;

    &>.#{$prefix}-item>.#{$prefix}-content {
      background: transparent;
    }
  }

  &>.#{$prefix}-item {
    border-color: $primaryColor;

    >.#{$prefix}-header {
      color: $textColor;
      background-color: $clientColor;
    }
  }

  &-content {
    color: $textColor;
    background: $clientColor;
  }
}

.#{$prefix}.module-collapse {
  border: none;

  .#{$prefix}-item {
    border: 1px solid $textColorSecondary;
    margin-bottom: 16px;
    box-shadow: 4px 4px 0px $lightGray;

    .#{$prefix}-header {
      font-weight: 600;
    }
  }

  .#{$prefix}-content {
    border-top: none;

    .#{$prefix}-content-box {

      >.#{$antPrefix}-col {
        display: flex;
        flex-direction: column;

        h5 {
          font-size: 13px;
          text-transform: uppercase;
          margin-bottom: 0;
          color: $textColorSecondary;
          font-weight: 400;
        }

        h5+span {
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 16px;
        }
      }

      .#{$prefix}.chapter-collapse {
        border: 0;

        .#{$prefix}-item {
          margin-bottom: 10px;
          border: 1px solid $primaryColor;
        }
      }

      .#{$antPrefix}-layout-content {
        background: transparent !important;
        padding: 16px !important;
      }
    }
  }

  .#{$prefix}-header {
    align-items: center;

    .#{$prefix}-extra {
      .#{$antPrefix}-btn-link {
        padding: 0;
        height: fit-content;
      }
    }
  }
}