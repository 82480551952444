@import 'variables';

$prefix: $antPrefix + -select;

.#{$prefix}-dropdown {
  background-color: $contrastBackground;
  .#{$prefix}-item {
    &:not(.#{$prefix}-item-option-disabled) {
      color: $textColor;
      &.#{$prefix}-item-option-selected {
        color: $primaryColor;
        background-color: $itemHoverBackground;
      }
      &:hover {
        background-color: $itemHoverBackground;
      }
    }
  }
}

%disabled {
  &.#{$prefix}-disabled {
    .#{$prefix}-selector {
      color: $disabledColor;
      background: $bodyBackground;
      border-color: $borderColor;
    }
    .#{$prefix}-arrow {
      color: $disabledColor;
    }
  }
}

.#{$prefix}-multiple {
  &:not(.#{$prefix}-disabled) {
    .#{$prefix}-selector {
      color: $textColor;
      background-color: $clientColor;
      border-color: $borderColor;
    }

    .#{$prefix}-selection-item {
      background: $itemHoverBackground;
      border-color: $itemHoverBackground;
    }
    .#{$prefix}-selection-item-remove {
      color: $borderColor;
      transition: color 0.3s;
      &:hover {
        color: $primaryColor;
      }
    }
  }

  &:hover, &:focus, &:focus-within {
    .#{$prefix}-selector {
      border-color: $secondaryColor !important;
    }
  }

  @extend %disabled;
}

.#{$prefix} {
  &.#{$prefix}-single:not(.#{$prefix}-customize-input) {
    .#{$prefix}-selector {
      transition: none;
    }
    &:not(.#{$prefix}-disabled) {
      .#{$prefix}-selector {
        color: $textColor;
        border-color: $borderColor;

        &:hover,
        &:focus,
        &:focus-within {
          border-color: $secondaryColor;
        }
      }
      .#{$prefix}-arrow {
        color: $textColor;
      }
      &.#{$prefix}-open,
      &:hover,
      &:focus,
      &:focus-within {
        .#{$prefix}-selector {
          color: $primaryColor;
          border-color: $secondaryColor;
          .#{$prefix}-selection-item {
            opacity: 1;
          }
        }
        .#{$prefix}-arrow {
          color: $secondaryColor;
        }
      }
    }
    &:not(.#{$prefix}-disabled):not(.#{$antPrefix}-pagination-options-size-changer) {
      .#{$prefix}-selector {
        background-color: $clientColor;
      }
    }
    @extend %disabled;
  }
  &-item-option-active:not(.#{$prefix}-item-option-disabled) {
    background-color: $itemHoverBackground;
  }
}

.#{$prefix}-focused .#{$prefix}-selector {
  border-color: $secondaryColor !important;
}

.#{$prefix}-item-option-selected:not(.#{$prefix}-item-option-disabled) .#{$prefix}-item-option-state {
  color: $primaryColor;
}